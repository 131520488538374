.home-container {
  min-width: 500px;
  min-height: 65vh;
}
.ask_button {
  width: 200px;
  height: 41px;
  background-color: #516cf0;
  color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  border: 1px solid transparent;
  font-size: 1rem;
}
.ask_button:hover {
  background-color: #ff8500;
}
.ask_button:active {
  border: 3px solid #516cf0;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.searchInput {
  height: 44px;
  opacity: 0.7;
  border-color: #516cf0 !important;
}
.searchInput:hover {
  border: solid 2px rgb(255, 148, 33);
}
